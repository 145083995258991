import React, { useEffect, useState, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { Pane, Tablist, Tab, toaster } from 'evergreen-ui'
import {
  generalStyles,
  sidesheetStyles,
  formStyles
} from '../../styles/sharedComponentStyles'
import UserInfo from './UserInfo'
import { GET_USER_ERROR_MESSAGE } from './userUtils'
import UserStudiesSites from './UserStudiesSites'
import UserBanner from './UserBanner'
import _ from 'lodash'
import { useQuery } from '@apollo/client'
import { GET_REALM_USER_BY_ID_QUERY } from '../../queries/getRealmUserById'
import LoadingSpinner from '../LoadingSpinner'
import AccessManagement from './AccessManagement'
import { useUserAccess } from '../../hooks/useRoleAccess'
import { useGlobalStore } from '../../hooks/useGlobalStore'
import { useHandleUserDetails } from '../../hooks/useHandleUserDetails'
import { getRealmIdFromURL } from '../../utils/filters'

const useSharedStyles = createUseStyles({
  ...generalStyles,
  ...sidesheetStyles,
  ...formStyles
})

const UserDetails = ({
  user,
  show,
  handleDetailsClose,
  parentSectionRef,
  isUserProfile = false
}) => {
  const sharedClasses = useSharedStyles()
  const [isBlocked, setIsBlocked] = useState(user?.isDisplayBlocked)
  const sideSheetEl = useRef(null)
  const { canEditUser: canEdit, imRealmAdmin } = useUserAccess(
    user,
    isUserProfile,
    isBlocked
  )

  const {
    title,
    tabSelectedIndex,
    showAccessManagement,
    handleModalAccessManagementOpenModal,
    handleAccessManagementCloseModal,
    setHeader,
    setTabSelectedIndex
  } = useHandleUserDetails({
    user,
    show,
    parentSectionRef,
    sideSheetEl,
    handleDetailsClose
  })

  return (
    <Pane
      ref={sideSheetEl}
      className={sharedClasses.sideSheet}
      data-testid="user-sidesheet"
    >
      {user && (
        <>
          <UserBanner title={title} user={user} />

          <Pane display="flex" marginTop="30px">
            <UserInfo
              user={user}
              setHeader={setHeader}
              isUserProfile={isUserProfile}
              isBlocked={isBlocked}
              setIsBlocked={setIsBlocked}
              canEdit={canEdit}
            />
          </Pane>

          <Pane className={sharedClasses.section} height={400}>
            <Tablist
              marginBottom={16}
              flexBasis={240}
              marginRight={24}
              className={sharedClasses.tablistModTwo}
            >
              <Tab
                aria-controls={`panel-studies-sites`}
                className={sharedClasses.tablistTab}
                id={0}
                onSelect={() => setTabSelectedIndex(0)}
                isSelected={0 === tabSelectedIndex}
              >
                Studies &amp; Sites
              </Tab>
            </Tablist>
            <Pane
              role="tabpanel"
              aria-labelledby="Studies"
              aria-hidden={0 !== tabSelectedIndex}
              display={0 === tabSelectedIndex ? 'flex' : 'none'}
            >
              <UserStudiesSites
                user={user}
                handleManageAccess={handleModalAccessManagementOpenModal}
                isBlocked={isBlocked}
                canEdit={canEdit}
                isUserProfile={isUserProfile}
                imRealmAdmin={imRealmAdmin}
              />
            </Pane>
          </Pane>
          {showAccessManagement && (
            <AccessManagement
              user={user}
              showModal={showAccessManagement}
              handleCloseModal={handleAccessManagementCloseModal}
            />
          )}
        </>
      )}
    </Pane>
  )
}

export const getUserWithStudies = (user, realmId) => {
  if (user) {
    const result = user.Site_Users?.reduce((acc, site) => {
      const studiesArray = site.Site?.Study_Sites?.filter(study =>
        realmId > 0 ? study.Study.realm_id === realmId : true
      ).map(study => ({
        study: study.Study.name,
        realmName: study.Study.Realm.name,
        site: { ...site.Site }
      }))
      return acc.concat(studiesArray)
    }, [])
    return {
      ...user,
      studies: _.groupBy(result, value => value.study)
    }
  }
  return null
}

export default function UserDetailsWrapper({
  userId,
  parentSectionRef,
  ...props
}) {
  const [userWithStudies, setUserWithStudies] = useState()
  const { realmId } = useGlobalStore()
  const routeRealm = window?.location
    ? Number(getRealmIdFromURL(window.location))
    : -1

  // Realm Id that is set depending on the realm from URL and the global store
  const realRealmId =
    routeRealm === false ? -1 : routeRealm ? routeRealm : realmId

  const { loading: pageLoading, data: currentUser } = useQuery(
    GET_REALM_USER_BY_ID_QUERY,
    {
      skip: !userId,
      variables: {
        userId
      },
      onError: _ => toaster.danger(GET_USER_ERROR_MESSAGE)
    }
  )

  useEffect(() => {
    const onUsersQuerySuccess = result => {
      const selectedUser = getUserWithStudies(result?.User_by_pk, realRealmId)
      setUserWithStudies(selectedUser)
    }
    if (currentUser) {
      onUsersQuerySuccess(currentUser)
    }
  }, [realRealmId, currentUser])

  if (pageLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      {userWithStudies && (
        <UserDetails
          {...props}
          user={userWithStudies}
          parentSectionRef={parentSectionRef}
        />
      )}
    </>
  )
}
